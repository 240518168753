import { Periods } from '../constants/periods'

// Overload type for getPeriodIndexForDate
type GetPeriodIndexForDate = {
  (gradYear: string | number, date?: Date): number
  (gradYear: null | undefined, date?: Date): null
  (gradYear: string | number | null | undefined, date?: Date): number | null
}

/**
 * Given a date, returns the index of the associated period in the constant Periods.
 *
 * @param gradYear Graduation year for the student, can be either a number or a string (which will be cast to a number if necessary)
 * @param date The date for which you want to know the period
 * @returns A number, which is the index in the "Periods" constant (`@roadmap/lib/constants/periods/Periods`) of the period identifier for the given date.  If the gradYear is not set, then it returns null.
 */
export const getPeriodIndexForDate = ((
  gradYear?: string | number | null | undefined,
  date = new Date()
): number | null => {
  if (!gradYear) return null
  const gradYearN = Number(gradYear)

  // The Periods array starts with '9th Grade Fall', and goes through 'Post Graduation'.  However it's easier to sort of work backwards...

  // 1. If the given date is in a later year than the grad year, or in the same year but after June, then they are post graduation
  if (date.getFullYear() > gradYearN || (date.getFullYear() === gradYearN && date.getMonth() + 1 > 6))
    // Return the index of the last item in the array
    return Periods.length - 1

  // 2. If the given date is in the same year as the grad year, but June or earlier, then they are 12th Grade X
  if (date.getFullYear() === gradYearN) {
    // Return the index of the last item in the array minus another amount.  Remember that getMonth() is 0-based, so January is 0, Feb is 1, etc.
    // If the date is in March, for example, this becomes `Periods.length - 1 - (6 - 2), which is the 5th item in from the end of the array
    return Periods.length - 1 - (6 - date.getMonth())
  }

  // 3. If the given date is in the year before the grad year, we're still in monthly territory, so same basic logic as above
  if (date.getFullYear() + 1 === gradYearN) {
    return Periods.length - 7 - (12 - date.getMonth())
  }

  // 4. If the given date is 2 or more years before the grad year, we're in to seasonal periods (Spring, Summer, Fall)
  // 10th spring, 11th summer, 11th fall
  if (date.getFullYear() + 2 === gradYearN) {
    // September through December are Fall
    if (date.getMonth() >= 8) return Periods.length - 20
    // July and August are Summer
    if (date.getMonth() >= 6 && date.getMonth() <= 7) return Periods.length - 21
    // January through June are Spring
    if (date.getMonth() <= 5) return Periods.length - 22
  }
  // 9th spring, 10th summer, 10th fall
  if (date.getFullYear() + 3 === gradYearN) {
    // Same as above
    if (date.getMonth() >= 8) return Periods.length - 23
    if (date.getMonth() >= 6 && date.getMonth() <= 7) return Periods.length - 24
    if (date.getMonth() <= 5) return Periods.length - 25
  }
  // 9th Grade fall has only a single period, from September to December.
  if (date.getFullYear() + 4 === gradYearN && date.getMonth() >= 8) return Periods.length - 25

  // Graduation date is far enough in the future that they are presumably not even in 9th grade yet
  return 0
}) as GetPeriodIndexForDate

type GetPeriodStringForDate = {
  (gradYear: string | number, date?: Date): string
  (gradYear: null | undefined, date?: Date): null
  (gradYear: string | number | null | undefined, date?: Date): string | null
}

export const getPeriodStringForDate = ((
  gradYear?: string | number | null | undefined,
  date = new Date()
): string | null => {
  const periodIndex = getPeriodIndexForDate(gradYear, date)
  if (typeof periodIndex === 'number') return Periods[periodIndex]
  return null
}) as GetPeriodStringForDate

export const getPeriodStringsForDateRange = (
  gradYear?: string | number | null | undefined,
  startDate = new Date(),
  endDate = new Date()
): string[] => {
  const startIndex =
    startDate <= endDate ? getPeriodIndexForDate(gradYear, startDate) : getPeriodIndexForDate(gradYear, endDate)
  const endIndex =
    startDate <= endDate ? getPeriodIndexForDate(gradYear, endDate) : getPeriodIndexForDate(gradYear, startDate)
  // Check for null explicitly, since 0 is a valid (but falsey) value
  if (startIndex === null || endIndex === null) return []
  if (startIndex === endIndex) return [Periods[startIndex]]
  const periodStrings: string[] = []
  for (let i = startIndex; i <= endIndex && i < Periods.length; i++) {
    periodStrings.push(Periods[i])
  }
  return periodStrings
}

export const getGradeFromGradYear = (yog?: string | null): number | null => {
  if (!yog) return null
  try {
    const gradYear = Number(yog)
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    if (gradYear < currentYear || (gradYear === currentYear && currentMonth > 6)) {
      // Student already graduated -- grad year is a prior year, or the current year but it's after June
      return null
    }
    if ((gradYear === currentYear && currentMonth <= 6) || (gradYear - 1 === currentYear && currentMonth > 6)) {
      return 12
    } else if (
      (gradYear - 1 === currentYear && currentMonth <= 6) ||
      (gradYear - 2 === currentYear && currentMonth > 6)
    ) {
      return 11
    } else if (
      (gradYear - 2 === currentYear && currentMonth <= 6) ||
      (gradYear - 3 === currentYear && currentMonth > 6)
    ) {
      return 10
    } else if (
      (gradYear - 3 === currentYear && currentMonth <= 6) ||
      (gradYear - 4 === currentYear && currentMonth > 6)
    ) {
      return 9
    } else if (
      (gradYear - 4 === currentYear && currentMonth <= 6) ||
      (gradYear - 5 === currentYear && currentMonth > 6)
    ) {
      return 8
    } else if (
      (gradYear - 5 === currentYear && currentMonth <= 6) ||
      (gradYear - 6 === currentYear && currentMonth > 6)
    ) {
      return 7
    } else if (
      (gradYear - 6 === currentYear && currentMonth <= 6) ||
      (gradYear - 7 === currentYear && currentMonth > 6)
    ) {
      return 6
    } else if (
      (gradYear - 7 === currentYear && currentMonth <= 6) ||
      (gradYear - 8 === currentYear && currentMonth > 6)
    ) {
      return 5
    } else if (
      (gradYear - 8 === currentYear && currentMonth <= 6) ||
      (gradYear - 9 === currentYear && currentMonth > 6)
    ) {
      return 4
    } else if (
      (gradYear - 9 === currentYear && currentMonth <= 6) ||
      (gradYear - 10 === currentYear && currentMonth > 6)
    ) {
      return 3
    } else if (
      (gradYear - 10 === currentYear && currentMonth <= 6) ||
      (gradYear - 11 === currentYear && currentMonth > 6)
    ) {
      return 2
    } else if (
      (gradYear - 11 === currentYear && currentMonth <= 6) ||
      (gradYear - 12 === currentYear && currentMonth > 6)
    ) {
      return 1
    }
  } catch (err) {
    console.error(err)
  }
  return null
}

export const coerceDateToDate = (date: string | number | Date | object | null | undefined): Date | null | undefined => {
  if (date instanceof Date) return date
  if (typeof date === 'string' || typeof date === 'number') return new Date(date)
  if (date === null || date === undefined) return date
  // This is a bit weird -- what we're really doing is checking to see if the date that was passed in was a Firebase Timestamp
  // however we can't import that type defintion -- the module relies on the 'fs' module, which isn't available
  // in the browser
  if (date instanceof Object && 'toDate' in date && typeof date.toDate === 'function') {
    return date.toDate()
  }
  return null
}

export const coerceDateToUTC = (date: string | number | Date | object | null | undefined): Date | null => {
  const tzOffset = new Date().getTimezoneOffset()
  const d = coerceDateToDate(date)
  if (!d) return null
  d.setMinutes(d.getMinutes() + tzOffset)
  return d
}

export const coerceDateToUTCMillis = (date: string | number | Date | object | null | undefined): number | null => {
  const d = coerceDateToUTC(date)
  return d ? d.getTime() : null
}
