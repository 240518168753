interface IPeriods {
  grade?: number
  friendly: string
}

export const Periods: string[] = [
  '9th Grade Fall',
  '9th Grade Spring',
  '10th Grade Summer',
  '10th Grade Fall',
  '10th Grade Spring',
  '11th Grade Summer',
  '11th Grade Fall',
  '11th Grade January',
  '11th Grade February',
  '11th Grade March',
  '11th Grade April',
  '11th Grade May',
  '11th Grade June',
  '12th Grade July',
  '12th Grade August',
  '12th Grade September',
  '12th Grade October',
  '12th Grade November',
  '12th Grade December',
  '12th Grade January',
  '12th Grade February',
  '12th Grade March',
  '12th Grade April',
  '12th Grade May',
  '12th Grade June',
  'Post Graduation',
]

export const PeriodData: { [key: string]: IPeriods } = {
  '9th Grade Fall': {
    grade: 9,
    friendly: '9th Grade - Fall',
  },
  '9th Grade Spring': {
    grade: 9,
    friendly: '9th Grade - Spring',
  },
  '10th Grade Summer': {
    grade: 10,
    friendly: '10th Grade - Summer',
  },
  '10th Grade Fall': {
    grade: 10,
    friendly: '10th Grade - Fall',
  },
  '10th Grade Spring': {
    grade: 10,
    friendly: '10th Grade - Spring',
  },
  '11th Grade Summer': {
    grade: 11,
    friendly: '11th Grade - Summer',
  },
  '11th Grade Fall': {
    grade: 11,
    friendly: '11th Grade - Fall',
  },
  '11th Grade January': {
    grade: 11,
    friendly: '11th Grade - January',
  },
  '11th Grade February': {
    grade: 11,
    friendly: '11th Grade - February',
  },
  '11th Grade March': {
    grade: 11,
    friendly: '11th Grade - March',
  },
  '11th Grade April': {
    grade: 11,
    friendly: '11th Grade - April',
  },
  '11th Grade May': {
    grade: 11,
    friendly: '11th Grade - May',
  },
  '11th Grade June': {
    grade: 11,
    friendly: '11th Grade - June',
  },
  '12th Grade July': {
    grade: 12,
    friendly: '12th Grade - July',
  },
  '12th Grade August': {
    grade: 12,
    friendly: '12th Grade - August',
  },
  '12th Grade September': {
    grade: 12,
    friendly: '12th Grade - September',
  },
  '12th Grade October': {
    grade: 12,
    friendly: '12th Grade - October',
  },
  '12th Grade November': {
    grade: 12,
    friendly: '12th Grade - November',
  },
  '12th Grade December': {
    grade: 12,
    friendly: '12th Grade - December',
  },
  '12th Grade January': {
    grade: 12,
    friendly: '12th Grade - January',
  },
  '12th Grade February': {
    grade: 12,
    friendly: '12th Grade - February',
  },
  '12th Grade March': {
    grade: 12,
    friendly: '12th Grade - March',
  },
  '12th Grade April': {
    grade: 12,
    friendly: '12th Grade - April',
  },
  '12th Grade May': {
    grade: 12,
    friendly: '12th Grade - May',
  },
  '12th Grade June': {
    grade: 12,
    friendly: '12th Grade - June',
  },
  'Post Graduation': {
    grade: 13,
    friendly: 'Post Graduation',
  },
}
